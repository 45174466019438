const projects = [
    {
        id: 1,
        title: 'Travel Website',
        description: 'Effortlessly book your trip using our sleek and user-friendly interface, designed with the latest HTML, CSS and JavaScript technologies.',
        image: '/travel.png',
        github: 'https://github.com/saurabhbakolia/Travel-Website.git',
        live: 'https://travel.scrollme.online',
    },
    {
        id: 2,
        title: 'Restaurant Website',
        description: 'Indulge in a culinary journey with our restaurant website. Our stunning and responsive user interface, crafted with the latest HTML, CSS and JavaScript technologies.',
        image: '/restaurant.png',
        github: 'https://github.com/saurabhbakolia/Restaurant-Website',
        live: 'https://restaurant.scrollme.online',
    },
    {
        id: 3,
        title: 'E-Commerce Website',
        description: 'Shop for your favorite products with our e-commerce website. Our sleek and responsive user interface, crafted with the latest HTML, CSS and JavaScript technologies.',
        image: '/ecommerce.png',
        github: 'https://github.com/saurabhbakolia/SCROLLME--ECOMMERCE-WEBSITE.git',
        live: 'https://scrollme.online',
    },
    {
        id: 4,
        title: 'ScrollMe Chat App',
        description: 'Chat with your friends with our ScrollMe Chat App. Our sleek and responsive user interface, crafted with the latest HTML, CSS and JavaScript technologies.',
        image: '/chat.png',
        github: 'https://github.com/saurabhbakolia/Real-Time-Chat-App.git',
        live: 'http://real-time-chat-app-coral.vercel.app/',
    },
    {
        id: 5,
        title: 'Weather App',
        description: 'Stay up-to-date with the latest weather conditions in your area using our Global Weather App! Built with cutting-edge technologies like Node.js, HTML, CSS, and Pug.',
        image: '/weather_app.png',
        github: 'https://github.com/saurabhbakolia/Global_Weather.git',
        live: 'https://github.com/Kritika30032002/Global_Weather.git',
    },
    // {
    //     id: 6,
    //     title: 'Coding Infinity',
    //     description: 'Discover the power of Coding Infinity! Our cutting-edge website boasts a sleek and responsive user interface, expertly crafted using the latest HTML, CSS , Bootstrap, and JavaScript technologies. Elevate your skills and unlock your potential with our comprehensive youtube playlist.',
    //     image: '/coding_infinity.png',
    //     github: '',
    //     live: '',
    // },
    // {
    //     id: 7,
    //     title: 'CV Screener',
    //     description: 'Revolutionize your hiring process with our CV Screener! Our state-of-the-art user interface, designed with the latest HTML, CSS and JavaScript technologies, allows you to quickly and efficiently screen thousands of CVs.',
    //     image: 'cv_screener.png',
    //     github: 'https://github.com/saurabhbakolia/CV-Screener.git',
    //     live: 'http://screener.scrollme.online',
    // },
    {
        id: 8,
        title: 'HJB Hall Website',
        description: 'Check out the HJB Hall Website. Our sleek and responsive user interface, crafted with the latest HTML, CSS and JavaScript technologies.',
        image: '/hjb_website.png',
        github: 'https://github.com/saurabhbakolia/frontEnd.git',
        live: 'https://hjb.iitkgp.co.in/html/index.html',
    },
    // {
    //     id: 9,
    //     title: 'have i been pwned clone',
    //     description: 'Check out the have i been pwned clone. Our sleek and responsive user interface, crafted with the latest HTML, CSS and JavaScript technologies.',
    //     image: '/haveyoubeenpwned.png',
    //     github: 'https://github.com/saurabhbakolia/pwnedPassword.git',
    //     live: 'https://github.com/saurabhbakolia/pwnedPassword.git',
    // },
    // {
    //     id: 10,
    //     title: 'Scrollme Stop Watch',
    //     description: 'Check out the scrollme stop watch, designed to perfection using the React, Tailwind CSS.',
    //     image: '/stop-watch.png',
    //     github: 'https://github.com/saurabhbakolia/quinx-stopwatch-app',
    //     live: 'https://quinx.scrollme.online/',
    // },
    // {
    //     id: 11,
    //     title: 'ScrollMe Library',
    //     description: 'ScrollMe Books: A Dynamic Library Management System',
    //     image: '/library.png',
    //     github: 'https://github.com/saurabhbakolia/Llibrary-Repo',
    //     live: 'https://library.scrollme.online/',
    // },
    {
        id: 12,
        title: 'Expense Tracker App',
        description: 'Track your expenses effortlessly with our Expense Tracker App. Manage your budgets, categorize expenses, and visualize spending patterns to make informed financial decisions. Stay on top of your finances with intuitive features and insightful reports.',
        image: '/Expense Tracker.png',
        github: 'https://github.com/saurabhbakolia/scrollme-expense-tracker',
        live: 'https://scrollme-expense-tracker.vercel.app/'
    },
    {
        id: 13,
        title: 'Scroll My Media',
        description: 'Connect, share, and engage with SocialMe, a vibrant social media platform designed for meaningful interactions. Share your moments, explore diverse content, join communities, and build lasting connections with like-minded individuals. Experience a new level of social networking with SocialMe.',
        image: '/scrollmy_media.png', 
        github: 'https://github.com/saurabhbakolia/scrollmy-media-app', 
        live: 'https://scrollmy-media-app.vercel.app/'
    }, 
    // {
    //     id: 14, 
    //     title: 'ScrollMe Sales CRM', 
    //     description: 'Empower your sales team with ScrollMe Sales CRM, a comprehensive platform for managing assignments, reviewing candidates, and streamlining the recruitment process. Create assignments, assign tasks, and review candidates seamlessly. Shortlist the best talent based on behavioral, communication, and situation handling scales. Access detailed candidate information, including name, profile, email, experience, hobbies, and introduction. Stay organized with assignment links, hours, and deadlines conveniently displayed. Enhance your recruitment efficiency with ScrollMe Sales CRM.', 
    //     image: '/sales_crm.png', 
    //     github: 'https://github.com/saurabhbakolia/sales_crm',
    //     live: 'https://scrollme-sales-ihvccrohr-saurabh-bakolias-projects.vercel.app/'
    // }
]

export default projects;